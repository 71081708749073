import React from 'react';

import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';

import SelfAffirmationCoverImage from '@/images/epub/self-affirmation/cover.png'
import PrepositionCoverImage from '@/images/epub/preposition/cover.png'

const HomeMainView = ({drawerOpen}: HomeMainViewProps) => {
  return (<>
    <div className="text-right">
      <span className="pr-1 text-green-600">믿을 수 있는 학습 시스템을 지향합니다.</span>
      <img className="float-right w-6" src={process.env.PUBLIC_URL + "logo32.png"} alt={'mippum logo'} />
    </div>
    <h2 className="mb-4 text-3xl font-bold leading-normal">새로운 소식</h2>
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <Card sx={{ maxWidth: 245, margin: 1 }}>
        <CardActionArea href="epub/preposition">
          <CardMedia
            component="img"
            height="140"
            image={PrepositionCoverImage}
            alt="preposition cover"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              미쁨1문1답<br /> 영문 전치사
            </Typography>
            <Typography variant="body2" color="text.secondary">
              2024-05-22 출판
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
      <Card sx={{ maxWidth: 245, margin: 1 }}>
        <CardActionArea href="epub/self-affirmation">
          <CardMedia
            component="img"
            height="140"
            image={SelfAffirmationCoverImage}
            alt="self-affirmation cover"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              영어로 하는<br />미쁨 자기긍정확언
            </Typography>
            <Typography variant="body2" color="text.secondary">
              2024-01-04 출판
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
    <h2 className="mt-10 mb-4 text-3xl font-bold leading-normal">준비중인 서비스</h2>
    <Stack spacing={2}>
      <Item>TTS 어플</Item>
    </Stack>
  </>);
};

interface HomeMainViewProps {
  drawerOpen: boolean;
}

export default HomeMainView;

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#f7f7f7',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
}));
