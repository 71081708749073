import React, {useEffect, useState} from 'react';

import Box from '@mui/material/Box';
import {Button, ButtonGroup} from '@mui/material'
import {isEmpty, isNotEmpty, shallowCopy} from "@/util/common/handle-object";
import {IQuestion, IQuiz} from "@/util/service/quiz/IQuiz";
import {loadSampleQuiz} from "@/util/service/quiz/content";

const QuizView = () => {

    const [total, setTotal] = useState<number>(0);
    const [currentIndex, setCurrentIndex] = useState<number>(0);

    const [quiz, setQuiz] = useState<IQuiz|null>(null);
    const [question, setQuestion] = useState<IQuestion|null>(null);
    const [selectOptionNumbers, setSelectOptionNumbers] = useState<number[]>([]);

    useEffect(() => {
        fetch().then();
        return () => { // clear up code
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetch = async () => {
        const sampleQuiz = await loadSampleQuiz();

        setQuiz(sampleQuiz);
        setQuestion(sampleQuiz.questions[0]);
        setTotal(sampleQuiz.questions.length);
    }

    const onClickOption = (optionNumber: number) => {
        if(question == null) return;
        if(isEnteredAnswer()) return;

        if(question.type==='MultipleChoice') {
            if(selectOptionNumbers.includes(optionNumber)) setSelectOptionNumbers([]);
            else setSelectOptionNumbers([optionNumber]);
            return;
        }
        if(question.type==='Checkboxes') {
            if(selectOptionNumbers.includes(optionNumber)) setSelectOptionNumbers(selectOptionNumbers.filter((selectOptionNumber) => selectOptionNumber !== optionNumber));
            else setSelectOptionNumbers(selectOptionNumbers.concat([optionNumber]));
            return;
        }
    }

    const onClickSubmitAnswer = () => {
        if(question == null) return;

        if(question.type==='MultipleChoice') {
            if(selectOptionNumbers.length !== 1) return;

            const newQuiz = shallowCopy(quiz);
            const newQuestion = shallowCopy(question);
            newQuestion.solverAnswer = selectOptionNumbers[0];
            newQuiz.questions[currentIndex] = newQuestion;
            setQuestion(newQuestion);
            setQuiz(newQuiz);
        }
    }

    const checkCorrect = () => {
        if(question == null) return false;
        if(question.type==='MultipleChoice') {
            if(isEmpty(question.solverAnswer)) return false;
            if(question.correctAnswer === question.solverAnswer) return true;
        }
        return false;
    }

    const checkNotCorrect = () => {
        if(question == null) return false;
        if(!isEnteredAnswer()) return false;

        if(question.type==='MultipleChoice') {
            if(question.correctAnswer !== question.solverAnswer) return true;
        }
        return false;
    }

    const changeQuestion = (questionNumber: number) => {
        if(total < questionNumber) return;
        if(quiz == null) return;
        if(question == null) return;

        const newQuestion = quiz.questions[questionNumber-1];
        setCurrentIndex(questionNumber-1);
        setQuestion(newQuestion);
        setSelectOptionNumbers([]);
    }

    const isEnteredAnswer = () => {
        if(question == null) return false;
        if(isEmpty(question.solverAnswer)) return false;
        if(question.type==='MultipleChoice') {
            if(typeof question.solverAnswer === 'number') return true;
        }
        return false;
    }

    if(quiz === null || question == null) {
        return (<></>);
    }

    return (<>
        <Box className={'p-2.5 max-w-3xl'}>
            <Box>
                <p>{question.questionBody}</p>

                {question.options && question.options.length > 0 ? <ButtonGroup orientation="vertical" aria-label="Vertical button group" sx={{width:'90%', marginTop: '10px'}}>
                        {question.options.map((option, index) => (<Button
                            variant={selectOptionNumbers.includes(index+1) ? 'contained': 'outlined'}
                            onClick={() => onClickOption(index+1)}
                            sx={{width:'90%', justifyContent: "flex-start"}}>{optionSymbols[index]} {option}</Button>))};
                </ButtonGroup>:''}
            </Box>

            {isEnteredAnswer() ? '': <Box className={'mt-2'} sx={{paddingLeft:'30%'}}>
                    <Button variant={'outlined'} onClick={() => onClickSubmitAnswer()}>확인</Button>
                </Box>}

            {checkCorrect() ? <Box className={'mt-4'} sx={{paddingLeft:'5%'}}>
                🙆🏻‍♀️정답입니다.😀
            </Box>: ''}
            {checkNotCorrect() ? <Box className={'mt-4'} sx={{paddingLeft:'5%'}}>
                🙅🏻‍♂️오답이에요.😭
            </Box>: ''}

            {isEnteredAnswer() && isNotEmpty(question.help?.summary) ? <Box className={'border-2 rounded-md mt-4 pb-2' + (checkCorrect() ? ' border-blue-400': ' border-fuchsia-400') }>
                    <div className='pt-1'><span className={'' + (checkCorrect() ? 'bg-blue-300': 'bg-fuchsia-400')}>요약 설명</span></div>
                    <p className={'m-2'}>{question.help?.summary}</p>
                </Box>: ''}

            {isEnteredAnswer() && isNotEmpty(question.help?.explanation) ? <Box className={'border-2 border-amber-500 rounded-md mt-2 pb-2'}>
                    <div className='pt-1'><span className='bg-fuchsia-400'>상세 설명</span></div>
                    <p className={'m-2'}>{question.help?.explanation}</p>
                </Box>: ''}

            {isEnteredAnswer() && currentIndex + 1 < total ? <Box className={'mt-2'} sx={{paddingLeft:'30%'}}>
                <Button variant={'outlined'} onClick={() => {changeQuestion(currentIndex+2)}}>다음</Button>
            </Box>: ''}
            <Box className={'mt-2'} sx={{paddingLeft:'32%'}}>
                {currentIndex+1} / {total}
            </Box>
        </Box>
    </>)
}

const optionSymbols = ['A.', 'B.', 'C.', 'D.', 'E.']

export default QuizView;
