import store from 'store2';


const getLocalStorage = (key: string) => {
  const stored = store.get(key);
  return stored;
}

const setLocalStorage = (key: string, data: any) => {
  store.set(key, data);
}

export {getLocalStorage, setLocalStorage};
