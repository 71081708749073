
const getNowStr = () => {
  const now = new Date();
  return `${now.getFullYear()}-${padLeft(now.getMonth() + 1)}-${padLeft(now.getDate())} ${padLeft(now.getHours())}:${padLeft(now.getMinutes())}:${padLeft(now.getSeconds())}`;
}

const padLeft = (nr:number, len = 2, padChr = `0`) =>
  `${nr < 0 ? `-` : ``}${`${Math.abs(nr)}`.padStart(len, padChr)}`;

export { getNowStr };
